import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';

const FaceRecognitionWebcam = () => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [detectedFaces, setDetectedFaces] = useState([]);

  useEffect(() => {
    const loadModelsAndDetect = async () => {
      try {
        // Load face-api.js models
        await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
        await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
        await faceapi.nets.faceRecognitionNet.loadFromUri('/models');

        setLoading(false);

        // Get access to webcam
        navigator.mediaDevices.getUserMedia({ video: {} }).then((stream) => {
          webcamRef.current.srcObject = stream;
        });

        // Face detection loop
        setInterval(async () => {
          if (webcamRef.current && webcamRef.current.video.readyState === 4) {
            // Detect faces
            const detections = await faceapi.detectAllFaces(webcamRef.current.video, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks()
              .withFaceDescriptors();

            setDetectedFaces(detections);

            // Log detections for now
            console.log('Detected faces:', detections);
          }
        }, 100); // Adjust interval as needed
      } catch (error) {
        setError(error);
      }
    };

    loadModelsAndDetect();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Webcam ref={webcamRef} />

      {/* Visual feedback for detected faces */}
      {detectedFaces.map((face, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            border: '5px solid red',
            top: face.box.top,
            left: face.box.left,
            width: face.box.width,
            height: face.box.height
          }}
        />
      ))}
    </div>
  );
};

export default FaceRecognitionWebcam;
